.transport-contain{
        height: 95vh;
        width: 95vw;
        box-shadow: rgba(243, 193, 193, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow-x: hidden;
        }
        
        .transport-form{
            height:100%;
            /* align-items: center; */
            justify-content: center;
            display: flex;
        }
        
        .transport-form-fields{
            display: ruby;
        }
    
        .trans-card{
            display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
        border-radius: 20px;
       
        background: rgba( 255, 255, 255, 0.5 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 19px );
        -webkit-backdrop-filter: blur( 19px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        /* height:250px;
        border-radius: 20px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
        }
        .trans-bg-svg{
        background-image: url("../../../assets/image/stores/formify-bg-2.svg");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        }
        .trans-bg-svg-crds{
            align-items: center;
            justify-content: center;
            display: flex;
            
          
        }
        .trans-card img{
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            /* width: 100%;
            height: 100%;
            object-fit: cover; */
        }
        
        .transport-input::placeholder{
            /* padding: 5px 5px; */
            color: rgb(16, 15, 15);
            font-size: 16px;
        }
        
        .transport-input {
            border: 1px solid #c0c0c0;
            height: 45px;
            border-radius: 5px;
            width: 700px;
        outline: none;
        padding:0px 10px;
        }
    
        .transport-input:hover{
            border: 1px solid #2653c6;
        }
        
        
        /* .transport-button{
            height: 40px;
            border-radius: 5px;
            width: 300px;
            color: white;
            background-color: #2653c6;
        }
        .transport-button:hover{
            background-color: #12317e;
        } */
        .transport-summery-p{
        margin: auto;
        align-items: center;
        justify-content: center;
        display: grid;
        text-align: center;
        text-transform: capitalize;
                                
        }
    
        .transport-summery-p span{
            color: rgb(253, 143, 40);
            text-transform: capitalize;
                                
        }
    
        .transport-login-link{
    color:#2653c6;
    font-size: 18px;
        }
        .transport-login-link:hover{
            color: #000000;
        }
    
        .frm-error{
    color: red;
    font-size: 18px;
        }
    
        .error-div{
            margin: auto;
    width: 700px;
        }
        .map-div{
            margin: auto;
            width: 700px;  
        }
        .trans-bg-svg-crds-p {
color:rgb(0, 0, 0);
font-size: 20px;
font-weight: 600;
text-transform: capitalize;
                                

        }

        .trans-bg-svg-crds-p-span{
               color:#2653c6; 
               font-size: 25px;
               font-weight: 700;
               text-transform: capitalize;
                                
        } 
        .trans-card-profile{
                        display: flex;
                    justify-content: center;
                    align-items: center;      
        }
        .trans-bg{
                background-color:#2653c6 ;
                align-items: center;
                justify-content: center;
                display: flex;
           
               
        }
        .profile-frm-p{
color: white;
font-size: 24px;
text-transform: capitalize;
font-weight: 600;
        }
        .profile-frm-p-span{
                color: rgb(255, 182, 47);
                font-size: 35px; 
                text-transform: capitalize;
                                     
        }

        .transport-prf-crd{
height: 480px;
object-fit: cover;
        }
     
        @media screen and (max-width:700px){
                .transport-contain{
                        /* min-height: 60vh; */
                        /* height:100%; */
                      /* background: red; */
                      box-shadow: none;
                        }
                        .trans-bg{
                                background-color:#2653c6 ;
                             height: auto;
                               
                        }
                .transport-input{
                    width: 350px;
                    padding:0px 10px;
                }
                .transport-button{
                    width: 300px;
                }
                .trans-card{
                    height:200px;
                    }
                    .error-div{
                            margin: auto;
                    width: 350px;
                        }
                        .map-div{
                            margin: auto;
                            width: 350px;  
                        }
                        .trans-bg-svg-crds-p {
                                color:rgb(0, 0, 0);
                                font-size: 14px;
                                font-weight: 400;
                                text-transform: capitalize;
                                        }
                                
                                        .trans-bg-svg-crds-p-span{
                                               color:#2653c6; 
                                               font-size: 14px;
                                               font-weight: 700;
                                               text-transform: capitalize;
                                        } 
                                        .profile-frm-p{
                                                font-size: 18px;
                                               text-align: justify;
                                                font-weight: 400;
                                                        }
                                                        .profile-frm-p-span{    
                                                                font-size: 25px; 
                                                                                        
                                                        }
                                                        
        .transport-prf-crd{
                height: auto;
                object-fit: cover;
                        }


        }
        @media screen and (max-width:400px){
                .transport-input{
                        width: 300px;
                }
                .error-div{
   
                        width: 300px;
        }
}
       
        @media screen and (min-width:1000px) and (max-width:1400px){
                .transport-input{
                        width: 462px;
                        padding:0px 10px;
                    }
                    .error-div{
                        margin: auto;
                width: 462px;
                    }
                    .map-div{
                        margin: auto;
                        width: 462px;  
                    }
                    .trans-bg-svg-crds-p {
                       
                        font-size: 18px;
                        font-weight: 500;
                        text-transform: capitalize;
                        
                                }
                        
                                .trans-bg-svg-crds-p-span{
                                       color:#2653c6; 
                                       font-size: 18px;
                                       font-weight: 700;
                                       text-transform: capitalize;
                                } 


        }


    
        /* ============================ANIMATION ============================= */
    
        .bounce-top {
            -webkit-animation: bounce-top 0.9s both;
                    animation: bounce-top 0.9s both;
        }
    
        /* ----------------------------------------------
     * Generated by Animista on 2024-2-12 12:57:53
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info. 
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */
    
    /**
     * ----------------------------------------
     * animation bounce-top
     * ----------------------------------------
     */
    @-webkit-keyframes bounce-top {
        0% {
          -webkit-transform: translateY(-45px);
                  transform: translateY(-45px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 1;
        }
        24% {
          opacity: 1;
        }
        40% {
          -webkit-transform: translateY(-24px);
                  transform: translateY(-24px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        65% {
          -webkit-transform: translateY(-12px);
                  transform: translateY(-12px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        82% {
          -webkit-transform: translateY(-6px);
                  transform: translateY(-6px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        93% {
          -webkit-transform: translateY(-4px);
                  transform: translateY(-4px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        100% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
      }
      @keyframes bounce-top {
        0% {
          -webkit-transform: translateY(-45px);
                  transform: translateY(-45px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 1;
        }
        24% {
          opacity: 1;
        }
        40% {
          -webkit-transform: translateY(-24px);
                  transform: translateY(-24px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        65% {
          -webkit-transform: translateY(-12px);
                  transform: translateY(-12px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        82% {
          -webkit-transform: translateY(-6px);
                  transform: translateY(-6px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        93% {
          -webkit-transform: translateY(-4px);
                  transform: translateY(-4px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        25%,
        55%,
        75%,
        87% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        100% {
          -webkit-transform: translateY(0px);
                  transform: translateY(0px);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
      }
      