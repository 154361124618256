.AdminLogin-contain{
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('../../assets/image/loginbg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .AdminLoginbutton{
    width: 100%;
    height: 45px;
    background-color:#ffffff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  .AdminLogin-form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(9px);
    color: #fff;
    border-radius: 12px;
    padding: 30px 40px;
  }
  .AdminLogin-h1{
    font-size: 36px;
    text-align: center;
  }
  .AdminLogin-input-box{
    position: relative;
    width: 100%;
    height: 50px;
    
    margin: 30px 0;
  }
  .AdminLogin-input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
    font-size: 16px;
    color: hsl(0, 0%, 100%);
    padding: 20px 45px 20px 20px;
  }
  .AdminLogin-input-box input::placeholder{
    color: #fff;
  }
  .AdminLogin-input-box .Admin-login-icon{
    position: absolute;
    right: 20px;
    top: 30%;
    transform: translate(-50%);
    font-size: 20px;
  }
   .AdminLogin-remember-forgot{
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
    /* visibility: hidden; */
  }
  .AdminLogin-remember-forgot label input{
    accent-color: #fff;
    margin-right: 3px;
  
  }
  .AdminLogin-remember-forgot a{
    color: #fff;
    text-decoration: none;
  
  }
  .AdminLogin-remember-forgot a:hover{
    text-decoration: underline;
  }

  .AdminLogin-register-link{
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
    visibility: hidden;
  
  }
  .AdminLogin-register-link p a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  .AdminLogin-register-link p a:hover{
    text-decoration: underline;
  }