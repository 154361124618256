body {
    margin: 0;
    padding: 0;
}

.background-transport {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    /* z-index: -1; */
}

@keyframes fadeIn{
    from {opacity: 0;} tp {opacity: 1;}
}

.fade-in{
    animation: fadeIn 500ms ease-in-out;
}