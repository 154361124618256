.home .carousel-item{
	min-height: 100vh;
	background-position: center;
	background-size: cover;
	position: relative;
	z-index: 1;

}
.home .carousel-item:before{
	content: '';
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: -1;
}
.home .carousel-item .container{
	position: absolute;
	left: 50%;
	top:50%;
	transform: translate(-50%,-50%);
	text-align: center;
}
.home .carousel-item  h2{
	font-size: 50px;
	color: #ffffff;
	margin:0 0 10px;
	opacity: 0;
}
.home .carousel-item p{
	font-size: 20px;
	margin:0;
	color: #eeeeee;
	opacity:0;
}
.home .carousel-item.active h2{
	animation: fadeInLeft 0.5s ease forwards;
}
.home .carousel-item.active p{
	animation: fadeInRight 0.5s ease forwards;
}

@keyframes fadeInLeft{
	0%{
		opacity: 0;
		transform: translateX(-30px);
	}
	100%{
		opacity: 1;
		transform: translateX(0px);
	}
}
@keyframes fadeInRight{
	0%{
		opacity: 0;
		transform: translateX(30px);
	}
	100%{
		opacity: 1;
		transform: translateX(0px);
	}
}
.home .control-slide{
	position: absolute;
	left: 50%;
	bottom: 40px;
	z-index: 10;
	transform: translateX(-50%);
}

.home .carousel-indicators{
	position: relative;
	margin:0;
}


.home .carousel-indicators li{
	height: 70px;
	width: 70px;
	margin:0 5px;
	border-radius: 50%;
	background-position: center;
	background-size: cover;
	border:3px solid transparent;
	transition: all 0.3s ease;
}

.home .carousel-indicators li.active{
	border-color: #ffffff;
	transform: scale(1.2);
}


.home .carousel-control-next, 
.home .carousel-control-prev{
	height: 60px;
	width: 60px;
	opacity: 1;
	z-index: 3;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
	transition: all 0.3s ease;
}
.home .carousel-control-next:hover, 
.home .carousel-control-prev:hover{
   box-shadow: 0 0 10px #ffffff;
}
.home .carousel-control-next img, 
.home .carousel-control-prev img{
   width: 30px;
}
.home .carousel-control-next{
	right: -90px;
}
.home .carousel-control-prev{
	left: -90px;
}

/*responsive*/
@media(max-width: 767px){
	.home .carousel-control-next, 
	.home .carousel-control-prev{
		display: none;
	}
	.home .carousel-indicators li{
		height: 60px;
		width: 60px;
	}
	.home .carousel-item h2{
		font-size: 25px;
	}
	.home .carousel-item p{
		font-size: 16px;
		text-align: justify;
		padding: 0px 15px;
	}
  }
  