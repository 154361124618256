.transport-vendor-hero{
    position: relative;
    /* margin:0px 120px; */
    margin-top: 120px;
}
.transport-vendor-hero-text{
    color: var(--white);
    font-size: 70px;
    font-weight: 500;
    line-height: 70px;
}
.transport-hero-explore{
    display: flex;
    align-items: center;
    gap:50px;
    width: fit-content;
    margin-top: 70px;
    padding: 5px 8px;
    padding-left: 30px;
    border-radius: 60px;
    background-color: var(--white);
    cursor: pointer;
    position: relative;
}
.transport-hero-explore p{
 /* color: var(--blue); */
 font-size: 18px;
 font-weight: 500;
}
.transport-vendorhero-dot-play{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.transport-hero-dots{
    display: flex;
    align-items: center;
    gap:23px;
    list-style: none;
}
.hero-dot{
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 7.5px;
    cursor: pointer;
    position: relative;
}
.hero-dot.orange{
    background: orangered;
}
.transport-hero-play{
    display: flex;
    align-items: center;
    gap:30px;
}
.transport-hero-play p{
    color: #fff;
    font-size: 19px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .transport-vendor-hero-text{
        font-size: 40px;
    }
    .transport-hero-explore{
       margin: auto;
       margin-top: 40px;
    }
    .transport-vendorhero-dot-play{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        position: relative;

    }
    .transport-hero-play{
       margin-bottom: 40px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .transport-vendor-hero{
        position: relative;
        /* margin:0px 120px; */
        margin-top: 120px;
      margin: auto;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .transport-vendor-hero{
        position: relative;
        /* margin:0px 120px; */
        margin-top: 120px;
      /* margin: auto; */
    }
    .transport-hero-explore{
        margin: auto;
        margin-top: 40px;
     }
     .transport-vendorhero-dot-play{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        position: relative;

    }
    .transport-hero-play{
        margin-bottom: 40px;
     }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .transport-vendor-hero{
        position: relative;
        margin:0px 120px;
        margin-top: 120px;
    }
}

