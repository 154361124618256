.home-banner {
  width: 100%;
  min-height: 50vh;
  background-color: #2653c6;
  position: relative;
}
.home-page{
  margin-top:80px;
}
.header {
  width: 100%;
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2vw 6vw;
  z-index: 1000;
}
.banner-last-cnt{
display: flex;
}
.arrow-icon-style{
  border-radius:50%;
  border:1px solid white;
  padding:5px;
  font-size:30px;
 }

.logo {
  font-size: 24px;
  color: #fff;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.menuitems {
  display: flex;
  margin: auto 0;
}

.menuitems li {
  list-style: none;
  margin: 0 1.5vw;
}

.menuitems li a {
  display: block;
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: capitalize;
}
.location-icon {
  background-color:white;
  width: 30px;
  height:30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgbox {
  display: flex;
  justify-content: center;
  height: 425px;
}

.heading{
 
  text-transform: capitalize;
  font-weight: 600;
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  position: relative;

}
.heading::before{
  content: attr(data-text);
  position: absolute;
  top:0;
  left: 0;
  width:0;
  height: 100%;
  color: white;
  -webkit-text-stroke: 0vw black;
  overflow: hidden;
  /* border-right: 2px solid white; */
  animation: text-animate 6s linear infinite;
}

.navbar-para{
text-align: justify;

}
.text-container {
  
  padding: 4rem;
  color: white;
  letter-spacing: 1px;

}

.home-btn {
  width: 250px;
  background-color: white;
  color: #103cbe;
  height: 8vh;
  border-radius: 16px;
  text-align: center;
  margin-top: 12px;
  padding-top: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease;
}

.image-container {
  position: relative;
  z-index: 1;
}

.animblob {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30vw;
  width: 30vw;
  transform: translate(-50%, -50%);
  background-color: #fff;
  animation: animate_blob 8s linear infinite;
  z-index: -1;
}

@keyframes animate_blob {

  0%,
  100% {
    border-radius: 45% 55% 70% 35% / 70% 30% 90% 40%;
  }

  25% {
    border-radius: 55% 45% 30% 35% / 50% 30% 60% 10%;

  }

  50% {
    border-radius: 65% 35% 35% 65% / 40% 60% 30% 70%;

  }

  25% {
    border-radius: 35% 65% 60% 40% / 70% 30% 40% 60%;

  }
}

@keyframes  text-animate{
  0%{
    width: 0;
  }
  70%{
    width: 100%;
  }
}


@media screen and (max-width :900px){
  .logo{
    font-size: 18px;
  }
  .text-container {
  padding: 2rem;
  }
  .text-container {
    padding: 2rem;
    color: white;
    letter-spacing: 1px;
  }
  .heading{
    font-size: 24px;
  }
  .banner-last-cnt{
    display: block;
   text-align: center;
    }
    .arrow-icon-style{
  display: inline-flex;
  margin: 10px 0;
  rotate: 90deg;
    }

    .animblob {
   
      height: 50vw;
      width: 50vw;
   
    }
    }

/* 
@media screen and (max-width: 1900px) {
  .animblob {
    height: 23vw;
    width: 23vw;
  }
  .imgbox {
    height: 420px;
  }
} */
@media screen and (max-width: 600px) {
  .animblob {
    height: 100vw;
    width: 90vw;
  }
}

@media screen and (min-width:1000px ) and (max-width:1300px){
  .imgbox {
    height: 585px;
  }
  .animblob {
  
    height: 50vw;
    width: 40vw;
   
  }
}