.cat-carousel{
    padding: 5rem;
   /* background-color: green; */
    /* color:#103cbe; */
    width:100vw;
    /* height: 100vw; */
}
.cat-box{

    height: 300px;
  background-color: #2653c6;
  padding: 20px;
 
}
.cat-heading{
    font-size: 16px;
    padding: 5px;
    color: white;
    margin-top: 28px;
    text-transform: uppercase;
    font-weight: 500;
}
.cat-icon {
    font-size: 40px;
    color:#c4b631;
    background-color: white;
    width:60px;
    height:60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
    
}
.cat-para{
    color: white;
    font-size: 14px;
    margin-top: 10PX;
   text-transform: capitalize;
}
.slick-slide div{
    margin: 0 1rem;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #103cbe !important;
    -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 600px){
    .slick-slide div{
margin: 0rem;
    }
    .cat-box{
       margin-bottom: 20rem;
    }
}
