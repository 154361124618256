.vendor-login{
    width: 100%;
    height: 100vh;
    background-color: #e8eefb; 
    display: flex;
    align-items: center;
}

.vendor-login .container{
    flex-wrap: wrap;
    
}
.toggle-password-button{
	margin-left: -30px;
}

.vendor-login .container .card{
    border: none;
	border-radius: 10px;
	background-color: #4270C8;
	width: 350px;
	margin-top: -60px;

}

.vendor-login p.mb-1{
	font-size: 25px;
	color: #9FB7FD;
}
.btn-primary{
	border: none;
	background: #5777DE;
	margin-bottom: 60px;
}
.btn-primary small{
	color: #9FB7FD;
}

.btn-primary span{
	font-size: 13px;
}

/* card two */

.card.two{
	border-top-right-radius: 60px;
	border-top-left-radius: 0;
}

/* custom inputs */

.form_group_login{
    position: relative;
    padding: 15px 0;
    margin-top: 5px;
    width:100%;
}
.form_field_login{
 width:100%;
 border: 0;
 border-bottom: 2px solid #9b9b9b;
 outline: 0;
}

.button-bg-modify{
	background-color: var(--blue);
	padding:10px;
	width: auto;
	height:"auto";
	border-radius: 5px;
	color: #ffffff;
}