.store-detail-page{
    /* background: #e8e8e8; */
    width: 100%;
    height: auto;
}
.store-form-area{
    padding-top: 2%;
	

}

.row .store-detailform{
   
    margin-top: 50px;
    margin-bottom: 50px;
}

.store-left{
    background:  #2653c6;
	height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
  
}

.store-left h2 {
	font-family: poppins;
	color: #fff;
	font-weight: 700;
	font-size: 38px;
    text-transform: capitalize;
}
.store-left h2 span {
	font-weight: 100;
}
.store-right{
    /* background-color: aqua; */
    height: 90vh;
    overflow-y: auto; 
    padding: 30px;
    box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
 
}

.store-right::-webkit-scrollbar {
    width: 6px; 
  }

  .veg-form-img {
    width: 70%;
    height:70%;
    object-fit: contain;
  
  }

  /* animations */

  .fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-23 10:58:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  