.modal-centering{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
   
    z-index: 9999; 
}

.modal-confirm {
    color: #636363;
    width: 325px;
    height: 100vh;
    font-size: 14px;
   
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}

.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    justify-content: center;
}

.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #2653c6;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box .modal-icon {
    font-size: 58px;
    position: relative;
    top: 3px;
    left:2px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #2653c6;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
    
 
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: #2653c6;
    outline: none;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}

/* =====================================vendor account modal===================== */
.vendor-account-modal{
    height: 450px;
    width: 350px;
   border-radius: 10px;
   box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.2);
   background:var(--blue);
   overflow: hidden;
    position:absolute;
    top:10%;
    left:0;
    right: 0;
    margin:auto;

  .account-ds-top{
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    width: 350px;
    height: 80px;
    background:var(--blue);
    animation: dsTop 1.5s;
  }
  .account-vendor-profile{
    position: absolute;
    margin: auto;
    top:40px;
    left:0;
    right: 0;
    width:100px;
    height:100px;
    border-radius: 50%;
    background: var(--blue);
    box-shadow: 0 0 0 5px #151515,
    inset 0 0 0 5px #000000,
    inset 0 0 0 5px #000000,
    inset 0 0 0 5px #000000,
    inset 0 0 0 5px #000000,;
    overflow: hidden;
    animation: mvTop 1.5s;

    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
  }
  .vendor-name{
    position: absolute;
    margin: auto;
    top: -60px;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    animation: fadeIn 2s ease-in;
  }

  .vendor-store-name{
    color:var(--black1);
    font-weight: 600;
  }
  .vendor-acccout-detail{
    position:absolute;
   left:10px;
    top:40px
  }
  .acccount-change-password{
    position: absolute;
 left:10px;

  }
  .vendor-account-personal{
    color:var(--black1);
    font-weight: bold;
   font-size: 14px;
    
  }
  button{
    position: absolute;
    left:135px
  }
}
.account-modal-content{
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.vendor-account-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
   
    z-index: 9999; 
}

@keyframes dsTop {
    0%{top:-150px};
    100%{top:0}
}