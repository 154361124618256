.ag-format-container {
  width: 900px;
  margin: 0 auto;
}


.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  
  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}


.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color:#2653c6;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}
/* .ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
} */
.ag-courses-item_title {
  min-height: 50px;
  margin: 0 0 45px;    
  overflow: hidden;
  text-align: center; 
  font-weight: bold;
  font-size: 20px;
  color: #FFF;
  z-index: 2;
  position: relative;
}

.ag-course-icon{
  font-size: 12px;
    color: #fff;
  text-align: justify;
padding-top: 1rem;
font-weight: 200;

}
.ag-courses-item_date-box {
  font-size: 14px;
  color: #FFF;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}
.ag-courses-item_bg {
  height: 110px;
  width: 128px;
  background-color: #f9b234;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.ag-courses-item_bg-iconPage {
  height: 180px;
  width: 150px;
  background-color: #f9b234;
  z-index: 1;
  position: absolute;
  bottom: -90px;
  left: -50px;
  border-radius: 45%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.ag-course-lefticon{
 color: white;
 margin: 20px 60px;
 font-size: 60px;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

.admin-title-heading{
  font-size: 18px;
  font-weight: 600;
text-transform: capitalize;
  position: relative;
}
.admin-title-heading::after {
  content: '';
  position: absolute;
  padding-top: 2rem;
  bottom: 0;
  top:10%;
  left: 50%;
  transform: translateX(-50%);
  width: 5%; 
  border-bottom: 3px solid var(--blue);
}

.custom-overflow-x {
  overflow-x: auto; 
}

/* ===============================================CARD STYLING ============================================== */