
nav{
	position:absolute;
	top:0;
	left:0;
	display:flex;
	flex-direction:column;
	justify-content: space-between;
	width:20%;
	min-height: 100vh;
	background-color: var(--blue);
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
	z-index: 1000;
	
}
/* .admin-dashboard-page{
	display: flex;
	justify-content: space-between;
} */
.dashboardmain{
	width:100%;
	/* margin-left: 2rem; */
	/* margin-right: 2rem; */
	margin-top: 2rem;

}
.bottom-dropdown{
	display: block;
} 
.visiblenavbar{
	transform: translateX(-100%);
	position:absolute;
}

.nav-btn-angle-icon{
	position: absolute;
	transform: translateX(38px);
	top: 0px;
	right: 0;
	width:40px;
	height: 40px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--blue);
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 5px 10px;
	cursor: pointer;
	color:var(--white);
}

.logo{
	display: block;
	width: 100px;
	margin: auto;
	background:transparent;
}

.logo img{
	display: block;
	width: 100%;
	height: 100%;
}
.admin-nav-link:hover{
	color: var(--blue);
}

.admin-nav-link{
	display: flex;
	align-items: center;
	color:var(--white);
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	margin-bottom: 5px;
	font-size: 14px;
}

.admin-nav-link:hover{
	border: 1px solid var(--white);
	color: var(--blue);
}

.admin-nav-link span{
	margin-left: 10px;
	color:var(--white);
}
.admin-nav-link span:hover{
	margin-left: 10px;
	/* color:var(--blue); */
}


.mobile-nav{
	background-color:var(--blue);
	width: 100%;
	height:40px;
	display: none;
}

.mobile-nav-btn{
	color:var(--white);
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}
.admin-nav-icon{
	color: var(--white);
}

@media only screen and (max-width: 768px){
	.admin-navbar{
		width: 40%;
		padding: 10px;
	}
	.dashboardmain{
	display: flex;
	justify-content: center;
	
	}
}

@media only screen and (max-width: 450px){
	.mobile-nav{
		display: flex;
		align-items: center;
	}
	nav{
		width: 70%;
		top: 38px;
		min-height: calc(100vh - 38px);
	}

	.nav-btn-angle-icon{
		display: none;
	}
}