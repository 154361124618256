@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;800;900&display=swap');


*{
  box-sizing: border-box;
  margin: 0;
  padding:0;

}
.App{
  background-color: #fefefe;
    height: 100vh;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

:root {
  --blue:#2653c6;
  --white:#ffffff;
  --gray:#f5f5f5;
  --black1:#222;
  --black2:#999;
  --red:#cc3a3a;
  --lightblue:#e6e8f4;
}
.main-bg{
  background-color: #2653c6;
}

.twelve h1 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}

.twelve h1:before,
.twelve h1:after {
  background-color:yellow;
  content: '';
  display: block;
  height: 3px;
  width: 55px;
  margin-bottom: 5px;
}

.twelve h1:after {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 0.25em;
}
.error{
  color:red;
  margin-top: 1px;
}

.arrow-pointer{
  cursor: pointer;
}

/* text animation */

.text-focus-in {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-1-22 14:50:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
/* scroll bar color */
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff; 
  border-radius: 20px; 
}


