.demo{ 
    background-color: var(--blue); 
 height: 100vh;
 width:100vw;
 /* position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader{
    width: 20px;
    height: 100px;
    margin: 50px auto 0;
    position: relative;
   
}
.inner-loader{
    height: 100px;
    position: absolute;
    animation: rotate 1.5s linear infinite;
}
.inner-loader .circle1,
.inner-loader .circle2,
.inner-loader .circle3,
.inner-loader .circle4,
.inner-loader .circle5{
    background:white;
    border-radius: 50%;
}
.inner-loader .circle1{
    height: 25px;
    width: 25px;
}
.inner-loader .circle2{
    height: 20px;
    width: 20px;
}
.inner-loader .circle3{
    height: 15px;
    width: 15px;
}
.inner-loader .circle4{
    height: 10px;
    width: 10px;
}
.inner-loader .circle5{
    height: 5px;
    width: 5px;
}
.inner-loader:nth-child(1){
    animation-delay: 0s;
    left: 0px;
}
.inner-loader:nth-child(2){
    animation-delay: 0.1s;
    left: 2px;
}
.inner-loader:nth-child(3){
    animation-delay: 0.2s;
    left: 5px;
}
.inner-loader:nth-child(4){
    animation-delay: 0.3s;
    left: 7px;
}
.inner-loader:nth-child(5){
    animation-delay: 0.4s;
    left: 10px;
} 
@keyframes rotate{
          0%{ transform: rotate(0deg); }
    100%,66%{ transform: rotate(360deg); }
}   