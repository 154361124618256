.vendor-page{
 
    width: 100%;
    height: 100vh; 
	
}

.form-area{
    /* padding-top: 2%;
	padding-bottom: 10%; */

height: 95vh; 
}

.row.single-form {
	box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
}
.vendor-page .left {
	background:  #2653c6;
	padding: 390px 77px;

}
.vendor-page  .left img{
   margin-top: -55%;
	padding-bottom: 10%;
	
}
.vendor-page .left h2 {
	color: #fff;
	font-weight: 700;
	font-size: 40px;
    text-transform: capitalize;
	text-align: left;
}
.left h2 span {
	font-weight: 100;
}
.single-form{
    background: #fff;

}
.vendor-page .right {
	padding: 70px 36px;
	position: relative;
	height: 90vh;
	overflow-y: scroll;
}
.vendor-icon{
    position: absolute;
	font-size: 80px;
	left: -47px;
	top: 40%;
	color: #fff;

}

/* .form-control {
	border: 2px solid #000;
} */
.right button {
	border: none;
	border-radius: 0;
	background: #252525;
	width: 180px;
	color: #fff;
	padding: 15px 0;
	display: inline-block;
	font-size: 16px;
	margin-top: 20px;
	cursor: pointer;
}
.right button:hover{
    background-color: #252525;
}

.frm-left{
	background:  #2653c6;
	align-items: center;
	justify-content: center;
	display: flex;
	padding: 5%;

}

.frm-left-cntdiv{
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	
}
.frm-left-imgdiv{

	height: 400px;
	width: 400px;

	
}

.frm-left-imgdiv img{
width: 100%;
height: 100%;

}
.text-focus-in span{
font-size: 50px;
color: white;
}
.text-focus-in-www{
font-weight: 800;
}

@media (min-width:768px) and (max-width:991px){
    .vendor-icon {
	top: -52px;
	transform: rotate(90deg);
	left: 50%;
}
}

@media (max-width:767px){
    .left {

  text-align: center;
}
/* .left h2 {
  font-size: 25px;
} */
.right {
  padding: 25px;
}
.vendor-icon {
  top: -52px;
  transform: rotate(90deg);
  left: 46%;
}
    .right button {
	width: 150px;
	padding: 12px 0;
}
.vendor-page .left h2 {
	font-size: 20px;
	text-align: center;
}
.vendor-page .left{
	padding: 146px 36px;
}
.vendor-page .left img {
    margin-top: -40%;
}
.frm-left-imgdiv{
/* background-color: red; */
	height: 200px;
	width: 200px;	
}


.text-focus-in span{
	font-size: 30px;
	color: white;
	}
}
