.veg-container {
        background-color: #f4f5fa;
        /* background-color:blue; */
        height: 450px;
        margin-top: 80px;
        padding: 4rem;
}

.pointer{
        cursor: pointer;
}
.veg-container .contain-box {
        background-color: #fff;
        width: 100%;
        height: 350px;
        padding: 2rem;
        border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.veg-img {
        display: flex;
        justify-content: center;
        padding: 2rem;
}

.veg-content {

        height: 100%;
        border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
}

.veg-img img {
        height: 300px;
        border-radius: 54px 54px 54px 54px;
        -webkit-border-radius: 54px 54px 54px 54px;
        -moz-border-radius: 54px 54px 54px 54px;
}

.veg-left {
        background-color: #f4f5fa;
        height: 100%;
        border-radius: 26px 26px 26px 26px;
        -webkit-border-radius: 26px 26px 26px 26px;
        -moz-border-radius: 26px 26px 26px 26px;
}

.veg-left img {
        width: 100%;
        height: 300px;
        object-fit: contain;
}

.veg-right {
        padding-left: 2rem;
        text-align: justify;
        height: 600px;
        /* background-color: #2653c6; */
}

.veg-right h3 {
        font-size: 18px;
        font-weight: 600;
}

.veg-para {
        font-size: 14px;
        margin-top: 12px;
        line-height: 30px;

}

.veg-icon {
        font-size: 18px;
        margin: 10px;
        color: #2653c6
}

.store-contain-veguser {
        display: flex;
        flex-direction: row;

        justify-content: center;
}

.store-info {
        background-color: #fff;
        margin: 2rem;
        height: 600px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.store-info img {
        width: 100%;
}

/* swiper */

.swiper {
        width: 100%;
        height: 35%;
        margin-top: 1rem;
}

.swiper-slide {
        text-align: center;
        font-size: 18px;
        background-color: aquamarine;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
}

.swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
}

.store-details {
        display: flex;
        align-items: center;
}

.store-heading {
        font-weight: 600;
        text-transform: capitalize;
}

.store-points {
        font-size: 16px;
        font-weight: 600;

}

.store-detail-point {
        font-size: 14px;
        font-weight: 400;
}

.service-details {
        font-size: 16px;
        text-align: justify;
}

/* mobile responsive */

@media (max-width: 1100px) and (min-width: 768px) {
        .veg-para {
                line-height: 25px;
        }
  
}

@media (max-width: 767px) {
        .veg-container {
                height: auto;
                padding: 1rem;
        }

        .veg-container .contain-box {
                height: 700px;
                margin-bottom: 20px;
        }

        .veg-right {
                margin-top: 15px;
        }
}

@media (max-width: 530px) {
        .veg-container .contain-box {
                height: 900px;
                margin-bottom: 20px;
        }

}