.storeImage-page{
  background-image:url("../../../../assets/image/vegetable.jpeg");
  background-size: contain;
  background-position: center;
  background-position: cover;
  height: 100vh;
}

.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*
    background: rgb(254, 254, 254); */
   
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;

  }
  

  
  .modal-wrapper.open {
    display: flex; 
  }
  
  .modal-wrapper.close{
    display: none;
  }
  .veg-upload-img{
    width: 70%;
    filter: drop-shadow(5px -5px 5px #8190db);
    
  }
  .img-displaying-space{
    max-height: 200px;
    

  }

  .horizontal-scroll-container {
    overflow-x: auto;
    white-space: nowrap;
}
.selected-image-container {
    display: inline-block;
    margin-right: 10px; 
}

.selected-image {
    max-width: 100px; 
    max-height: 100px; 
}
.img-cancel{
    background-color:#e95b57;
   padding: 7px;
   color: white;
   cursor: pointer; 
}
.img-upload{
    background-color: #2653c6;
   padding: 7px;
   color: white;
   cursor: pointer;
}
.img-upload-btn{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.horizontal-scroll-container::-webkit-scrollbar {
    width: 3px;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
    background-color: #2653c6; 
}

.horizontal-scroll-container::-webkit-scrollbar-track {
    background-color: #eee; 
}


.horizontal-scroll-container {
    scrollbar-width: thin; 
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
}

.horizontal-scroll-container::-webkit-scrollbar-track {
    background-color: #eee; 
}
