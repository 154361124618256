
/* @font-face {
    font-family: 'PlayfairDisplay-VariableFont_wght.ttf';
    src: url('./assets/fontstyle/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf');
} */

.footsocialicon{
  display: flex;
  justify-content: center;

}
.pro-top-center-link-div{
  justify-content:center;
  color:white;
  display:flex;
}
       
.pro-top-center-link{
color: yellow;
font-size: 16px;

}
.pro-top-center-link:hover{
color: white;
}

.socialiconspan{
  margin: 7px;
  /* justify-content: space-between; */
}
.footer-top{
display: flex;
justify-content: space-between;
}
.professionaltit-h5 {

  align-items: center;
  display: flex;
  font-size: 18px;
  color: white;
  font-weight: bold;
  position:relative;
      }
  
      .professionaltit-h5::after{
        content:"";
        position:absolute;
        border: 2px solid rgb(255, 255, 255);
        width: 50px;
        bottom:-5px;
        /* left:0px; */
        justify-content: center;
       
      }
  .professional-footer-bg{
    background-color: #2653c6;
}

.professional-col{
    align-items: center;
    display: flex;
    flex-direction: column;
   }
 
.professional-li{
    align-items: center;
    justify-content: start;
    display: flex;
    color:white;
  }
  
  .professional-a {
    float: left;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    text-transform: capitalize;
    color: #ffffff;
    font-size: 16px;
  }
  .professional-a:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 3px;
    width: 0;
    background: transparent;
    font-size: 20px;
  }
  .professional-a:hover {
    color: white;
  }
  .professional-link:after {
    transition: width .5s ease, background-color .5s ease, left .5s ease;
    color: white;
  }
  
  .professional-link:hover:after {
    width: 100%;
    left: 0;
    background: #ffffff;
    /* color: red; */
  }

  .professional-i{
    padding: 9px;
    font-size: 25px;
    color:white;
  }

  .edge-links span{
    margin-right: 9px;

  }

  .sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
  }

  .sb_footer-below-links{
    display: flex;
    flex-direction: row;
  }

  .sb_footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
  }

  hr{
    color: white;
    
  }

  .sb_footer-copyright{
    justify-content: center;
    display: flex;
    align-items: center;
}

  .sb_footer-copyright p{
    font-size: 13px;
    line-height: 20px;
    color: white;
    font-weight: 600;
  }
  .pro-social-icons-style{
   color:white;
  border-radius:50%;
   padding:5px;
   font-size:30px;
   border:1px solid white
  }

  @media screen  and (max-width:600px){
    .sb_footer-below{
        flex-direction: column;
        justify-content: left;
    }
  
    .sb_footer-below-links{
        flex-direction: column;
    }
    .sb_footer-below-links p{
        /* margin-left: 8rem; */
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-left: 0rem;
    }
    .professionaltit-h5 {

align-items: center;
display: flex;
font-size: 20px;
font-weight: bold;
position:relative;
    }

    .professionaltit-h5::after{
      content:"";
      position:absolute;
      border: 2px solid rgb(255, 255, 255);
      width:75px;
      bottom:-5px;
      justify-content: center;
    }
    .professional-li{
        /* justify-content: center; */
align-items: center;
display: flex;
    }
    .professional-col{
      align-items: start;
      /* display: flex;
      flex-direction: column; */
     }
     .footer-top{
     text-align: center;
     display: block;
      }
      .pro-top-center-link-div{
        display:block;
      }     
  }
  @media screen  and (max-width:820px) {
    .professional-col {
      flex-direction: row;
  }
  }
  @media screen  and (max-width:900px){
 
  .phone-link{
    color: white;
    text-decoration: none;
    
  }
  }
 
