@font-face {
    font-family:poppins-semibold;
    src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf");
  }
  
  @font-face {
    font-family:poppins-regular;
    src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf");
  }
  .font-check{
    font-family:poppins-regular
  }

/* Login container */
.box-area{
    width: 960px;
    overflow-x: hidden;
}

/* Right Box*/

.right-box{
    padding: 40px 30px 40px 40px;
    font-family: "poppins-regular";
}
.header-text{
    font-family: "poppins-semibold";
}


/* Custom Placeholder */
::placeholder{
    font-size: 14px;
}

/* For Small Screens */

@media only screen and (max-width : 768px){
    .box-area{
        margin: 0 10px;
    }
    .left-box{
        height: 100px;
        overflow: hidden;
    }
    .right-box{
        padding:20px;
    }
}