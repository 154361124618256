
.vegetable-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vegetable-modal-content{
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
.vegetable-modal-content {
  width: 350px;
}
.vegetable-modal-para{
  font-size: 14px;
  text-align: justify;
}
.vegetable-modal-content  button {
  width: 50%;
  background-color: var(--blue);
 height: 32px;
 margin-top: 10px;
 color: var(--white);
  border-radius: 4px;
}
.image-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 
 */
  overflow-y: auto;
  max-height: 90%; 
}
.image-scroll-container img{
  height: 450px;
  margin:10px;

 object-fit:contain;

 
}
.vendor-veg-img-content{
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width:80%;
  display: flex;
  align-items: center;
  justify-content: center;
margin-top: 2rem;
  flex-direction: column;
  height: 80%;
}

.vendor-veg-img-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-scroll-container .img-border{
  border: 1px solid var(--red);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
}

.store-pto-replace{
  background-color: var(--red);
  height:30px;
  padding :12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}
.store-img-cls{
  background-color: var(--blue);
  height:30px;
 width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}
.close-circle-icon{
  font-size: 28px;
  cursor: pointer;
  margin-bottom: 2px;
}

/* Story preview modal */

.story-preview-content{
  background: #fff;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width:300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 500px;
}
.story-preview-image{
  height: 80%;
  width:80%;
  object-fit: contain;
}
.story-video-preview{
height:400px;
  width:300px;

}
.modal-heading-story{
  font-size: 14px;
  margin:5px;
  font-weight:700;
  text-transform: capitalize;
}
.vegvendor-story-content{
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width:300px;
  display: flex;
  align-items: center;

  flex-direction: column;
  height: 500px;
}
.story-del-btn{
  background-color:var(--red);
  padding: 10px;
  color: #fff;
  border-radius: 2px;
}
.story-close-icon{
font-size: 1rem;
}
.story-preview-modal-res{
  display: flex;
  justify-content: center;
  align-items: center;
}
.vegetable-story-modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: relative; */
}
.story-delete-para{
  font-size: 12px;
}

.messageModalContent {
  display: flex;
  flex-direction: column;
  text-align: left;
 margin-top: 1rem;
 font-size: 16px;
 font-weight: 600;
 text-transform: capitalize;

}

.messageOkButton{
  background-color: #007bff; 
  color: #fff;
  border: none;
 margin-top: 2rem;
  cursor: pointer;
  border-radius: 4px;
}

.messageOkButton:hover {
  background-color: #0056b3; /* Darker shade on hover */
}