/* =========== Google Fonts ============ */
/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap"); */

/* =============== Globals ============== */

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
}

/* =============== Navigation ================ */
.navigation {
  position: fixed;
  width: 300px;
  height: 100%;
  background: var(--blue);
  border-left: 10px solid var(--blue);
  transition: 0.5s;
  overflow: hidden;
}
.navigation.active {
  width: 80px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
  background-color: var(--white);
}

.navigation ul li:nth-child(1) {
  margin-bottom: 40px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}
.navigation ul li:hover a,
.navigation ul li.hovered a {
  color: var(--blue);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
}
.navigation ul li a .icon  {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
 padding-left: 2rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
 
}

/* --------- curve outside ---------- */
.navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
}

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 300px);
  left: 300px;
  min-height: 100vh;
  background: var(--white);
  transition: 0.5s;
}

.main.active {
  width: calc(100% - 80px);
  left: 80px;
}

.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content:start;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}


.user {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.user img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ======================= Cards ====================== */
.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.cardBox .card {
  position: relative;
  background: var(--white);
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.cardBox .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2.5rem;
  color: var(--blue);
}

.cardBox .card .cardName {
  color: var(--black2);
  font-size: 1.1rem;
  margin-top: 5px;
}

.cardBox .card .iconBx {
  font-size: 2.5rem;
  color: var(--black2);
  display: flex;
  justify-content: end;
}

.cardBox .card:hover {
  background: var(--blue);
}
.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
  color: var(--white);
}


/* ====================== Responsive Design ========================== */
@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }
  .navigation.active {
    width: 300px;
    left: 0;
  }
  .main {
    width: 100%;
    left: 0;
  }
  .main.active {
    /* left: 300px; */
  }
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width: 480px) {

}


/* AdminDashboard.css */
.admin-navigation {
  opacity: 1;
  max-height: 100px; 
  overflow: hidden;
  transition: opacity 0.5s, max-height 0.5s;
}

.admin-navigation-hidden {
  opacity: 0;
  max-height: 0;
  transition: opacity 0.5s, max-height 0.5s;
}

.head-options{
  display: flex;
  justify-content: end;
  font-size: 1.5rem;

  
}

.edit-options{
 
  color:mediumblue;
  margin-left: 20px;
  cursor:"pointer"
}
.delete-options{
 
  color:firebrick;
  margin-left: 20px;
}
.verify-options{
 
  color:green;
  margin-left: 20px;
  margin-right: 20px;
}
.cancel-btn-edit{
background-color:var(--red);
padding: 8px;
border-radius: 4px;
color: white;
cursor: pointer;
}
.back-option{
 
  font-size: 20px;
  margin-left: 10px;
}
.save-btn-edit{
  background-color:var(--blue);
padding: 8px;
border-radius: 4px;
color: white;
cursor: pointer;
}
.delete-button{
background-color: red;
padding: 8px;
border-radius: 4px;
color: white;
margin-left: 10px;
margin-top: 10px;
width:3rem;
font-size: 1.5rem;
text-align: center;
cursor: pointer;

}

.admin-del-modal{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
 
}
.admin-del-point{
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.admin-main-heading{
  font-weight: 600;
  margin-left: 2rem;
}

.unverified-detailing {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
  margin-top: 1rem;
  padding: 1rem;
}
.detail-heading{
  display: flex;
  align-items: center;
  font-weight: 600;
  
}
.unverified-row{
  display: flex;
  padding: 1rem;
}
.detail-point-img{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
 
}
.image-container-admin{
  border: 1px solid var(--red);
  margin-top: 20px;
}
.image-preview{
  height: 500px;
  width: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  padding: 1rem;
}
.set-poster-button {
  
  background-color: var(--blue);
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px ;
}

.admin-choose-poster-btn{
  background-color: var(--blue);
  color:var(--white);
  font-size: 12px;
  padding: 10px;
  border-radius: 2px;
  cursor:pointer;
}

/* =====================================Master add category and sub category syling==================================== */

.add-martcategory-title{
  font-size:20px;
  font-weight: 600;
 font-family: 'Poppins' 
}

.add-martcategory-label{
  font-size:16px;
 font-weight: 400;
  margin-bottom: 10px;
  font-family: 'Poppins';
  color:var(--blue);
  margin-top:10px;
}
.mart-categorycard{
  display: flex;


}
.mart-card{
  width:auto;
  height:180px;
  background-color:var(--lightblue);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius:8px;
  margin: 2rem;
  cursor: pointer;
  padding-left:10px;
  padding-right: 10px;
}
.mart-category-img{
  width:100px;
  height:70px;
  object-fit: contain;


}


@media (max-width: 768px) {
  .mart-categorycard {
    flex-wrap: wrap; 
    justify-content: center; 
  }
  .mart-categorycard > .mart-card {
    flex-basis: calc(50% - 20px); 
    margin: 10px; 
  }
}
