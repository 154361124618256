.vendor-profile-content{

  margin-top: 1rem;
}
.vendor-profile-left{
  /* background-color: blue */
}
.store-contain{
 display: flex;
 flex-direction: column;
 align-items:center;
}
.profile-veg-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width:300px;
  height:auto;
  position: relative;
  
}
.profile-veg-card img{
  height: 200px;
  position: relative;
  width: 100%; 

}
.button-container{
  position: absolute;
  /* top: 30%; */
height: 200px;
  width: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
 
}
.webble-img{
  position:relative;
  width: 100%;
}
.webble-img:hover .overlay {
  height: 100%;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); 

  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.webble-img img{
  display: block;
  width: 100%;
  /* height: auto; */
}
.overlay .text {
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;

}
.overlay .img-btn{
  background-color: var(--red);
  height:30px;
  padding :12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.overlay .storie-btn{
  background-color: var(--blue);
  height:30px;
  padding :12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

/* ======================VENDOR STORY CSS ======================== */

.veg-vendor-story{
  margin-top: 4rem;
}
.veg-story-page {

}
.veg-add-story-btn{
  background-color: var(--blue);
  color:var(--white);
  height:45px;
  width: 70%;
 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.veg-add-product-btn{
  background-color: var(--blue);
  color:var(--white);
  height:45px;
  width: 10%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.veg-vendor-profile-edit-btn{
  font-size: 18px;
  display: flex;
  justify-content: end;
  margin:10px 0;
  color:var(--red)
}
 /* =========================================PRODUCT CARD DISPLAYING=============================== */
 @media screen and (max-width: 1300px){

   .veg-add-product-btn{
    width:200px;
    margin-bottom:10px;
   }
 }

 @media screen and (max-width: 600px){
  .store-contain{
    align-items: center;
   }
   .veg-add-story-btn{
width: 50%;
   }
   .veg-add-product-btn{
    width: 40%;
    margin-bottom:10px;
   }
 }

 /* =========================================PRODUCT CARD DISPLAYING=============================== */

.vendorProduct-heading{
  font-size: 14px;
  font-weight: 500;
 
}
.service-heading-vendor{
  font-weight: 600;
}
 .vendorProduct-scroller {
   display: flex;
   padding-bottom: 30px;
   overflow-x: auto;

   background-color: white;

   &::-webkit-scrollbar {
     height: 6px;
     background-color: rgb(159, 158, 158);
   }

   &::-webkit-scrollbar-thumb {
     background-color: #d4d3d3;
     border-radius: 3px;
   }

   &::-webkit-scrollbar-button {
    
   }
 }
.vendorProduct-containerbox{

  height:300px;
 background-color: white;
 padding: 10px;
 border-radius: 5px;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
 
}
 .vendorProduct-box{
  width: auto;
  height:200px;
  overflow-y: scroll;
 background-color: white;
 padding: 10px;
 margin: 10px;
 border-radius: 5px;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
 
 }
 .vendorProduct-title{
  font-size: 18px; 
   font-weight: 400;
   display: flex;
  padding-bottom: 3px;
  text-transform: capitalize;
  padding-left: 2rem;
  position: relative;
  left:50%;
  transform: translateX(-50%);

}
.vendorProduct-title::after{
  content:"";
  position:absolute;
  border: 1px solid rgb(31, 29, 134);
  width: 50px;
  bottom:-5px;
  /* left:0px; */

  justify-content: center;
}

.vendorProduct-scroll{
  padding:40px ;
  width:100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  overflow-x: auto;
}


@media (max-width:1300px) {

  .veg-add-story-btn{
    width: 50%;
  }
}






@media (max-width: 768px) {


  .vendorProduct-box{
    width: 300px;
    height: 150px;
  }
  
}

.vendorProduct-icon {
  display: flex;
  cursor: pointer;
  gap: 5px;
  justify-content: end;
  margin-bottom: 5px;
}
.vendorProduct-iconbar{
  font-size: 20px;
}
.vendorProduct-edit{
  background-color: #315fd2;
  padding: 3px;
  color: white;
  font-size: 22px;
  border-radius: 5px;
}
.vendorProduct-delete{
  background-color: #cc3a3a;
  padding: 3px;
  color: white;
  font-size: 22px;
  border-radius: 5px;
}


  @media (max-width:1000px) {
   
    .veg-add-story-btn{
      width: 50%;
    }
 
   
  }
  
  

  



 