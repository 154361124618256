.veg-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    /* padding: 100px; */
    display: block;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.scroll-bg{
background-color: rgb(45, 99, 21);
}
/*  */
.veg-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   background-color:#2653c6 ;
    z-index: 100;
}


.veg-header .veg-logo {
    position: relative;
    max-width: 80px;
    color: #333;
}

.veg-header ul {
    position: relative;
    display: flex;
}

.veg-header ul li a {
    display: inline-block;
    color: #333;
    font-weight: 400;
    margin-left: 40px;
}

.veg-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px

}

.veg-content .textBox {
    position: relative;
    max-width: 600px;
}

.veg-content .textBox h2 {

    color: #333;
    font-size: 4em;
    line-height: 1.4em;
    font-weight: 500;
}

.veg-content .textBox h2 span {
    color: #2653c6;
    font-size: 1em;
    font-weight: 900;
}

.veg-content .textBox p {
    color: #333;
    text-align: justify;
}

.veg-content .textBox a {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 20px;
    background: #2653c6;
    color: #fff;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;

}

.veg-content .imgBox {
    width: 600px;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    margin-top: 50px;
}

/* .veg-content .imgBox img{
    max-width: 650px;
} */



.thumb {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%);
    display: flex;
}

.thumb li {
    list-style: none;
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    transition: 0.5s;
}

.thumb li:hover {
    transform: translateY(-15px);
}

.thumb img {
    max-width: 60px;
}

.circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2653c6;
    clip-path: circle(600px at right 700px);
}

@media(max-width:991px) {
    .veg-header {
        padding: 20px;
    }

    .veg-header .veg-logo {
        position: relative;
        max-width: 80px;
    }

    .veg-header ul {
        display: none;
    }

    .veg-toggle {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background: url("../../../assets/image/stores/menu.png");
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        filter: invert(1);
        z-index: 11;
    }

    .veg-toggle.active {
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background: url("../../../assets/image/stores/close.png");
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #333333;



    }

    .veg-navigation.active {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1.5em;
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #fff;
        z-index: 10;

    }

    .veg-header ul li a {
        font-size: 0.7em;
    }


   

    .veg-content {
        flex-direction: column;
        margin-top: 50px;
    }

    .veg-content .textBox h2 {
        margin-bottom: 15px;
        font-size: 1.9em;
    }

    .veg-content .textBox {
        /* max-width: 100%; */
    }
    
    .circle {
        margin-top: 30px;
        clip-path: circle(400px at center bottom);
    }

    .veg-content .imgBox {
        max-width: 100%;

    }

    .veg-content .imgBox img {
        margin-top: 50px;
        margin-bottom: 100px;

    }

    .thumb img {
        max-width: 40px;

    }


}

@media(max-width:600px){
    .veg-content {
    padding: 0px 20px
}

}

@media(min-width:1400px){
    .veg-content {
        padding:  90px 170px; 
   
}
.veg-content .textBox {
    max-width: 700px;
}
.veg-content .textBox p {
    font-size: 18px;
}

}

@media(max-width:1200px){
    .veg-content .textBox {
        max-width: 450px;
    }

}

