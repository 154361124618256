
.login-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(to bottom right, #ff966d,#fa538d,#89379c); */
}

.login-container{
    width: 50vw;
    height: 60vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "login";
    box-shadow: 0 0 17px 10px rgb(0 0 0 / 30%);
    border-radius: 20px;
    background: white;
    overflow: hidden;
    position: relative;
}
.design{
    grid-area: design;
    display: none;
    position: relative;

}
 .design .pill-1{
    bottom:0;
    left:-40px;
    border-radius: 40px;
    position: absolute;
    width: 80px;
    height:200px;
    background: rgb(38,83,198);
    background: linear-gradient(77deg, rgba(38,83,198,1) 44%, rgba(255,255,255,1) 100%);
}
.design .pill-2{
    top:-150px;
    left:-80px;
    position: absolute;
    height: 450px;
    width: 160px;
    border-radius: 200px;
    /* border:30px solid #e2c5e2; */
    background: rgb(38,83,198);
background: linear-gradient(77deg, rgba(38,83,198,1) 44%, rgba(255,255,255,1) 100%);
}
.design .pill-3{
    top:-100px;
    left:160px;
    position: absolute;
    height: 200px;
    width: 100px;
    /* background: linear-gradient(#ff966d,#fa538d,#89379c); */
    border-radius: 70px;
    background: rgb(38,83,198);
background: linear-gradient(77deg, rgba(38,83,198,1) 44%, rgba(255,255,255,1) 100%);
}
.design .pill-4{
    bottom:-180px;
    left:220px;
    position: absolute;
    height: 300px;
    width: 120px;
    /* background: linear-gradient(#ff966d,#fa538d); */
    border-radius: 70px;
    background: rgb(38,83,198);
background: linear-gradient(77deg, rgba(38,83,198,1) 44%, rgba(255,255,255,1) 100%);
    
}
.rotate-45{
    transform :rotate(-45deg);
}
.login{
    grid-area: login;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    /* justify-content: center; */
}
.login h3.title{
    margin:15px 0;
    font-weight: 700;
}
.text-input{
    background: #e6e6e6;
    height:40px;
    display: flex;
    width: 80%;
    align-items: center;
    border-radius: 10px;
    padding: 0 15px;
    margin: 10px 0;
}

.text-input input{
    background: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    margin-left: 10px;
}

::placeholder{
    color:#9a9a9a
}

.text-input .login-icon{
    color: #686868;
}

.login-btn{
    width: 80%;
    padding: 10px;
    color:white;
    background: #2653c6;
    border:none;
    border-radius: 10px;
    cursor:pointer;
    margin-top:10px
}

a {
    font-size: 12px;
    color: #9a9a9a;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
}

a.forgot {
    margin-top: 15px;
}

.create {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    color: #2653c6;
}
@media (min-width :768px){
    .login-container{
        grid-template-columns: 50% 50%;
        grid-template-areas: "design login";
    }
    .design{
        display: block;
    }
}


