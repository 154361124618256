

.Transport-navbar__brand{
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
  .Transport-navbar__brand:hover{
    color: white;
    /* font-size: 30px; */
  }
  a {
  text-decoration: none;
  
  }
  
  li {
  list-style: none;
  }
  
  
  
  .Transport-container {
  max-width: 960px;
  height:100%;
  margin-left: auto;
  margin-right: auto;
  
  }
  
  @media screen and (max-width: 960px) {
  .Transport-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  }
  
  /* Navbar */
  .Transport-logo{
  color: var(--white);
  font-size: 30px;
  }
  .Transport-navbar__wrapper {
  position: sticky;
  top:0;
  background:var(--blue);
  z-index: 99;
  }
  
  .Transport-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  }
  
  
  
  .Transport-navbar__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
  }
  
  .Transport-navbar__link {
  color:rgb(255, 255, 255);
  font-size: 16px;
  
  }
  .Transport-navbar__link:hover {
    color:white;
  }
  .Transport-toggler__icon {
  cursor: pointer;
  display: none;
  }
  
  .Transport-toggler__icon div {
  width: 25px;
  height: 2px;
  background-color: white;
  /* background:var(--white); */
  margin: 5px 0px;
  transition: 0.3s ease;
  }
  
  
  
  
  
  @media screen and (max-width: 768px) {
  .Transport-toggler__icon {
    display: block;
    
  }
  .Transport-navbar__menu {
    position: fixed;
    /* background: var(--blue); */
  
  
  background: rgba( 255, 255, 255, 0.5 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15px );
  -webkit-backdrop-filter: blur( 15px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
    flex-direction: column;
    top: 10vh;
    width: 50%;
    height: 90vh;
    right: 0;
    transform: translateX(100%);
    transition: 0.5s ease;
    z-index:100;
  }
  .Transport-navbar__link {
    color:rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 700;
  }
  }
  
  
  
  .Transport-navbar__collapse {
  transform: translateX(0%);
  }
  
  /* Toggle Icon Animation */
  .Transport-toggle .line__1 {
  transform: translate(-5px, 7px) rotate(-45deg);
  }
  .Transport-toggle .line__2 {
  opacity: 0;
  }
  .Transport-toggle .line__3 {
  transform: translate(-5px, -7px) rotate(45deg);
  }
  
  
  