.modal-content{
    background-color:white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.category-header{
    z-index: 10;
}

.dropdown{
    width:100%;
   position: relative;

}

.dropdown .dropdown-btn{
    padding:15px 20px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    font-weight: bold;
    color:#333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown .dropdown-content{
    position: absolute;
    top:110%;
    left:0;
    width: 100%;
    padding: 10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    font-weight: 500;
    color:#333;
    
}
.dropdown .dropdown-content .dropdown-item{
   padding: 10px;
   cursor: pointer;
   transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover{
    background: #f4f4f4;
}